import { ShippingLabelParams } from '@mothership/document-types'

export const ShippingLabel: ShippingLabelParams = {
  billOfLadingNumber: 'BOL9374623',
  integratedCarrierName: 'XPO Logistics',
  numberOfLabels: 5,
  pickupNumber: 'MS6473823',
  contactName: '',
  deliveryAddress: {
    name: 'Mothership Technologies, Inc.',
    streetAddress1: '1234 Street Address Way',
    streetAddress2: 'Suite A',
    city: 'Unicorn Valley',
    state: 'CA',
    zip: '90016',
  },
}
