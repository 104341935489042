import { BillOfLadingShipmentType } from '@mothership/document-types'
import { Column } from '../Pane'
import { Text } from '../Text'

/**
 * Displays the PRO number section. If a PRO number is provided and this is a direct shipment,
 * displays that; otherwise, displays a prompt to place the PRO label here.
 */
export const BillOfLadingProNumber = ({
  proNumber,
  shipmentType,
}: {
  proNumber?: string
  shipmentType: BillOfLadingShipmentType
}): JSX.Element => {
  const proNumberTrimmed = proNumber?.trim()

  return (
    <Column border={'12px solid #000000'} padding={12} width={'100%'} alignItems="center">
      {proNumberTrimmed?.length && shipmentType === BillOfLadingShipmentType.Direct ? (
        <Text bold fontSize={16} color={'#000000'}>
          PRO #: {proNumberTrimmed}
        </Text>
      ) : (
        <>
          <Text bold fontSize={16} color={'#000000'}>
            PLACE PRO # LABEL HERE
          </Text>
          <Text fontSize={12} color={'#000000'}>
            LTL Carriers Only
          </Text>
          <Text fontSize={12} color={'#000000'}>
            Not required for local or truckload
          </Text>
        </>
      )}
    </Column>
  )
}
