import { BillOfLadingParams } from '@mothership/document-types'
import { Page, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment-timezone'
import {
  BOL_DEFAULT_MARGIN,
  BillOfLadingAccessorials,
  BillOfLadingBilling,
  BillOfLadingCarrierDetails,
  BillOfLadingFreight,
  BillOfLadingHeader,
  BillOfLadingInspection,
  BillOfLadingLocation,
  BillOfLadingNotes,
  BillOfLadingNotice,
  BillOfLadingProNumber,
  BillOfLadingSignatureLine,
} from '../components/BillOfLading'
import { PDFWrapper } from '../components/PDFWrapper'
import { Column, Row } from '../components/Pane'
import { DocumentType } from '../documents'
import { generateShippingLabelPages } from './ShippingLabel'

const styles = StyleSheet.create({
  page: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 20,
    paddingRight: 20,
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontSize: 8,
  },
})

export const BillOfLading: DocumentType<BillOfLadingParams> = ({
  renderValues,
  copies = 1,
}: {
  renderValues: BillOfLadingParams
  copies?: number
}): JSX.Element => {
  const { shippingLabelParams, ...bolParams } = renderValues

  const billOfLadingPages: Array<JSX.Element> = []
  for (let i = 0; i < copies; i++) {
    billOfLadingPages.push(generateBillOfLadingPage(bolParams, `bol_${i}`))
  }

  const shippingLabels = shippingLabelParams ? generateShippingLabelPages(shippingLabelParams) : []

  return (
    <PDFWrapper>
      <>{billOfLadingPages}</>
      <>{shippingLabels}</>
    </PDFWrapper>
  )
}

const generateBillOfLadingPage = (
  renderValues: Omit<BillOfLadingParams, 'shippingLabelParams'>,
  key: string,
): JSX.Element => {
  const {
    shipment,
    carrierInfo,
    carrierUser,
    pickupCarrier,
    deliveryCarrier,
    pickupSignatorName,
    pickupSignature,
    deliverySignatorName,
    deliverySignature,
    hasSeparatePickupAndDeliveryCarriers,
  } = renderValues

  const pickupActionTime = moment(shipment.freightReadyTimeAt)
    .tz(shipment.pickupLocation.timezone)
    .format('MM/DD/YY h:mma')
  const deliveryActionTime = moment(shipment.deliveryEtaAt).tz(shipment.deliveryLocation.timezone).format('MM/DD/YY')
  const carrierUserName = carrierUser ? `${carrierUser.firstName} ${carrierUser.lastName}` : undefined
  const carrierName = carrierInfo?.carrierName ?? carrierUserName

  // NOTE [jdao]: FBA is only on delivery for now.
  const fbaNotes = shipment.deliveryLocation.fulfillmentByAmazonId
    ? `${shipment.deliveryLocation.fulfillmentByAmazonId}${
        shipment.deliveryLocation.purchaseOrderNumber ? `; PO#: ${shipment.deliveryLocation.purchaseOrderNumber}` : ''
      }`
    : undefined

  return (
    <Page size="LETTER" style={styles.page} wrap key={key}>
      <BillOfLadingHeader
        serviceLevelStatement={shipment.serviceLevelStatement}
        isGuaranteed={shipment.isGuaranteed}
        isSameDay={shipment.isSameDay}
        isNextDay={shipment.isNextDay}
      />
      <Row marginTop={BOL_DEFAULT_MARGIN}>
        <Column flex={1} marginRight={BOL_DEFAULT_MARGIN / 2}>
          <BillOfLadingLocation
            type="Shipper"
            actionTime={pickupActionTime}
            city={shipment.pickupLocation.city}
            closeTime={shipment.pickupLocation.closeTime}
            name={shipment.pickupLocation.name}
            openTime={shipment.pickupLocation.openTime}
            phoneNumber={shipment.pickupLocation.phoneNumber}
            referenceNumber={shipment.pickupLocation.referenceNumber}
            shipmentType={shipment.type}
            state={shipment.pickupLocation.state}
            streetAddress1={shipment.pickupLocation.streetAddress1}
            streetAddress2={shipment.pickupLocation.streetAddress2}
            contactFirstName={shipment.pickupLocation.contactFirstName}
            contactLastName={shipment.pickupLocation.contactLastName}
            phoneNumberExtension={shipment.pickupLocation.phoneNumberExtension}
            zip={shipment.pickupLocation.zip}
          />
          <BillOfLadingLocation
            type="Consignee"
            actionTime={deliveryActionTime}
            city={shipment.deliveryLocation.city}
            closeTime={shipment.deliveryLocation.closeTime}
            isGuaranteed={shipment.isGuaranteed}
            name={shipment.deliveryLocation.name}
            openTime={shipment.deliveryLocation.openTime}
            phoneNumber={shipment.deliveryLocation.phoneNumber}
            email={shipment.deliveryLocation.email}
            referenceNumber={shipment.deliveryLocation.referenceNumber}
            shipmentType={shipment.type}
            state={shipment.deliveryLocation.state}
            streetAddress1={shipment.deliveryLocation.streetAddress1}
            streetAddress2={shipment.deliveryLocation.streetAddress2}
            contactFirstName={shipment.deliveryLocation.contactFirstName}
            contactLastName={shipment.deliveryLocation.contactLastName}
            phoneNumberExtension={shipment.deliveryLocation.phoneNumberExtension}
            zip={shipment.deliveryLocation.zip}
          />
          <BillOfLadingAccessorials
            pickupLocation={shipment.pickupLocation}
            deliveryLocation={shipment.deliveryLocation}
          />
        </Column>
        <Column flex={1} marginLeft={BOL_DEFAULT_MARGIN / 2}>
          <BillOfLadingProNumber proNumber={carrierInfo?.proNumber} shipmentType={shipment.type} />
          <BillOfLadingCarrierDetails
            bolNumber={carrierInfo?.bolNumber ?? shipment.referenceNumber}
            carrierName={carrierName}
            pickupNumber={carrierInfo?.pickupNumber}
            quoteId={carrierInfo?.quoteId}
          />
          <BillOfLadingBilling
            freightReadyTimeAt={shipment.freightReadyTimeAt}
            thirdPartyBillingAddress={carrierInfo?.thirdPartyBillingAddress}
          />
        </Column>
      </Row>
      <BillOfLadingFreight carrierInfo={carrierInfo} freight={shipment.freight} />
      <BillOfLadingNotes
        shipperNotes={shipment.pickupLocation.notes}
        consigneeNotes={shipment.deliveryLocation.notes}
        fbaNotes={fbaNotes}
      />
      <BillOfLadingNotice />
      <BillOfLadingSignatureLine
        showTrailerNumber
        label={'Shipper Signature:'}
        signaturePlainText={pickupSignatorName}
        signatureUrl={pickupSignature?.url}
        date={pickupSignature?.createdAt}
        timezone={shipment.pickupLocation.timezone}
      />
      {hasSeparatePickupAndDeliveryCarriers ? (
        <BillOfLadingSignatureLine
          label={'Pickup Driver Signature:'}
          signatureText={
            pickupCarrier && shipment.pickedUpAt ? `${pickupCarrier.firstName} ${pickupCarrier.lastName}` : undefined
          }
          date={pickupCarrier && shipment.pickedUpAt ? shipment.pickedUpAt : undefined}
          timezone={shipment.pickupLocation.timezone}
        />
      ) : (
        <BillOfLadingSignatureLine
          label={'Driver Signature:'}
          signatureText={carrierUser && shipment.pickedUpAt ? carrierUserName : undefined}
          date={shipment.pickedUpAt}
          timezone={shipment.pickupLocation.timezone}
        />
      )}
      <BillOfLadingSignatureLine
        label={'Consignee Signature:'}
        signaturePlainText={deliverySignatorName}
        signatureUrl={deliverySignature?.url}
        date={deliverySignature?.createdAt}
        timezone={shipment.deliveryLocation.timezone}
      />
      {hasSeparatePickupAndDeliveryCarriers && (
        <BillOfLadingSignatureLine
          label={'Delivery Driver Signature:'}
          signatureText={
            deliveryCarrier && shipment.deliveredAt
              ? `${deliveryCarrier.firstName} ${deliveryCarrier.lastName}`
              : undefined
          }
          date={deliveryCarrier && shipment.deliveredAt ? shipment.deliveredAt : undefined}
          timezone={shipment.deliveryLocation.timezone}
        />
      )}
      <BillOfLadingInspection
        type="Pick-up"
        hasInspectionPhotos={shipment.hasPickupInspectionPhotos}
        inspectionNotes={shipment.pickupInspectionNotes}
      />
      <BillOfLadingInspection
        type="Drop-off"
        hasInspectionPhotos={shipment.hasDeliveryInspectionPhotos}
        inspectionNotes={shipment.deliveryInspectionNotes}
      />
    </Page>
  )
}
