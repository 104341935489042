import { CreditType as DocumentCreditType } from '@mothership/document-types'
import { CreditType as FinanceCreditType } from '@mothership/finance/dist/api'

export const MOTHERSHIP_BILLING_ADDRESS_NAME = 'Mothership Technologies, Inc.'
export const MOTHERSHIP_BILLING_ADDRESS_STREET = '1401 Lavaca Street Unit #114'
export const MOTHERSHIP_BILLING_ADDRESS_CITY_STATE_ZIP = 'Austin, TX 78701'

export const MOTHERSHIP_PO_BOX_ADDRESS_STREET = 'PO Box 515381 PMB 48766'
export const MOTHERSHIP_PO_BOX_ADDRESS_CITY_STATE_ZIP = 'Los Angeles, CA 90051'

export function mapDocumentCreditTypeToFinanceCreditType(documentCreditType: DocumentCreditType): FinanceCreditType {
  switch (documentCreditType) {
    case 'creditNoteAllocation':
      return FinanceCreditType.CreditNoteAllocation
    case 'uncollectible':
      return FinanceCreditType.Uncollectible
    case 'promotional':
      return FinanceCreditType.Promotional
    case 'overpayment':
      return FinanceCreditType.Overpayment
    case 'other':
      return FinanceCreditType.Other
    case 'otherCustomerCredit':
      return FinanceCreditType.OtherCustomerCredit
    case 'referral':
      return FinanceCreditType.Referral
    case 'refund':
      return FinanceCreditType.Refund
    case 'retention':
      return FinanceCreditType.Retention
    case 'support':
      return FinanceCreditType.Support
    case 'termsRefund':
      return FinanceCreditType.TermsRefund
    default:
      return FinanceCreditType.Other
  }
}
