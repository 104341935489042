import { BillOfLadingShipmentType } from '@mothership/document-types'
import moment from 'moment'
import { formatPhoneNumberToNational } from '../../utils/string'
import { Column, Row } from '../Pane'
import { Text } from '../Text'
import { BOL_DEFAULT_BORDER, BOL_DEFAULT_PADDING } from './BillOfLadingDefaults'

interface LocationProps {
  type: 'Shipper' | 'Consignee'

  actionTime: string
  city: string
  closeTime: string
  name: string
  openTime: string
  phoneNumber: string
  email?: string
  referenceNumber?: string
  shipmentType: BillOfLadingShipmentType
  state: string
  streetAddress1: string
  streetAddress2?: string
  zip: string
  contactFirstName?: string
  contactLastName?: string
  phoneNumberExtension?: string

  isGuaranteed?: boolean
}

export const BillOfLadingLocation = (props: LocationProps): JSX.Element => {
  const {
    type,
    actionTime,
    city,
    closeTime,
    isGuaranteed,
    name,
    openTime,
    phoneNumber,
    email,
    referenceNumber,
    shipmentType,
    state,
    streetAddress1,
    streetAddress2,
    phoneNumberExtension,
    contactFirstName,
    contactLastName,
    zip,
  } = props

  const actionTimeLabel = type === 'Shipper' ? 'Ready at' : 'Deliver by'

  /* Do not show the delivery date for standard shipments */
  const hideActionLabel = type === 'Consignee' && shipmentType === BillOfLadingShipmentType.Integrated && !isGuaranteed

  const isValidString = (str: string | undefined): boolean => {
    return str !== undefined && str.length > 0
  }

  const contactName = () => {
    if (contactFirstName && contactLastName) {
      return `${contactFirstName} ${contactLastName}`
    } else if (isValidString(contactFirstName)) {
      return contactFirstName
    } else if (isValidString(contactLastName)) {
      return contactLastName
    }
    return undefined
  }

  const firstRowContent = () => {
    if (contactName()) {
      return <Text>{contactName()}</Text>
    }
    return <Text>{formatPhoneNumberToNational(phoneNumber)}</Text>
  }

  const secondRowContent = () => {
    if (contactName()) {
      return <Text>{formatPhoneNumberToNational(phoneNumber)}</Text>
    } else if (phoneNumberExtension && isValidString(phoneNumberExtension)) {
      return <Text>Ext. {phoneNumberExtension}</Text>
    }
    return undefined
  }

  const thirdRowContent = () => {
    if (contactName() && phoneNumberExtension && isValidString(phoneNumberExtension)) {
      return <Text> Ext.{phoneNumberExtension}</Text>
    }
    return undefined
  }

  return (
    <Column border={BOL_DEFAULT_BORDER} width={'100%'} marginTop={type === 'Consignee' ? 10 : 0}>
      {/** Header */}
      <Row
        backgroundColor="#eeeeee"
        width={'100%'}
        paddingLeft={BOL_DEFAULT_PADDING}
        paddingRight={BOL_DEFAULT_PADDING}
        paddingTop={2}
        paddingBottom={2}
        borderBottom={BOL_DEFAULT_BORDER}
      >
        <Text bold width={50}>
          {type}
        </Text>
        {hideActionLabel ? null : (
          <Row>
            <Text bold>{actionTimeLabel}: </Text>
            <Text>{actionTime}</Text>
          </Row>
        )}
        <Row justifyContent="flex-end" flex={1}>
          <Text bold>Open: </Text>
          <Text>
            {/* Open and Close times are already timezone adjusted, so no need to account for it here */}
            {moment(openTime, 'h:mma').format('h:mma')} - {moment(closeTime, 'h:mma').format('h:mma')}
          </Text>
        </Row>
      </Row>

      {/** Address */}
      <Column width={'100%'} padding={BOL_DEFAULT_PADDING}>
        <Row justifyContent="space-between" width={'100%'}>
          <Text>{name}</Text>
          {firstRowContent()}
        </Row>
        <Row justifyContent="space-between" width={'100%'}>
          <Text>{`${streetAddress1}${streetAddress2 ? `, ${streetAddress2}` : ''}`}</Text>
          {secondRowContent() && secondRowContent()}
        </Row>
        <Row justifyContent="space-between" width={'100%'}>
          <Text>
            {city}, {state} {zip}
          </Text>
          {thirdRowContent() && thirdRowContent()}
        </Row>
        {email && <Text>{email}</Text>}
      </Column>

      {/** Reference */}
      <Row borderTop={BOL_DEFAULT_BORDER} width={'100%'}>
        <Text
          backgroundColor={'#eeeeee'}
          bold
          borderRight={BOL_DEFAULT_BORDER}
          paddingTop={2}
          paddingBottom={2}
          paddingLeft={BOL_DEFAULT_PADDING}
          paddingRight={BOL_DEFAULT_PADDING}
        >
          Reference
        </Text>
        <Text paddingTop={2} paddingBottom={2} paddingLeft={BOL_DEFAULT_PADDING} paddingRight={BOL_DEFAULT_PADDING}>
          {referenceNumber || ''}
        </Text>
      </Row>
    </Column>
  )
}
